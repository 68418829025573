<template>
  <div class="columns">
    <div class="column is-5">
      <div class="container pt-5 pl-5">
        <img
          src="/img/logo-aeduc.png"
          width="300"
          alt="logo-aeduc"
          class="pb-6"
        />

        <div class="pt-6 is-size-3">
          <h1 class="aeduc-color pb-6">Te damos <br />la bienvenida a AEDUC</h1>
        </div>

        <div class="columns pt-5">
          <div class="column is-10">
            <form @submit.prevent="login">
              <div class="field is-size-5	">
                <label class="label-cuenta" for="email">Cuenta</label>
                <div class="control">
                  <input
                    v-model="form.email"
                    type="text"
                    class="input"
                    required
                    autocomplete="email"
                    autofocus
                    placeholder="Ingrese su email"
                  />
                </div>
              </div>

              <div class="field is-size-5">
                <label class="label-contraseña" for="password"
                  >Contraseña</label
                >
                <div class="control">
                  <b-input
                    v-model="form.password"
                    type="password"
                    required
                    autocomplete="current-password"
                    autofocus
                    placeholder="Ingrese su contraseña"
                    @keyup.native.enter="login"
                  />
                </div>
              </div>

              <div
                class="field is-form-action-buttons is-block has-text-centered"
              >
                <b-button
                  class="btn-ingresar is-size-5	"
                  type="is-info"
                  @click="login"
                  :loading="isLoading"
                  expanded
                >
                  Ingresar
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-9 login-background-blue ">
      <img src="/img/fondo-login.png" class="center-image" alt="imagen-fondo" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    form: {
      email: "", //"miguel.lopezt86@gmail.com",
      password: "" //"arcadia1399"
    },
    isLoading: false
  }),
  methods: {
    verifyUser() {
      axios
        .get("/api/user")
        .then(res => {
          if (res) {
            if (res.data.user.rol_id == "1") {
              location.href = process.env.VUE_APP_ADMIN_URL;
            }
            if (res.data.acceso.rol_id == "8") {
              location.href = process.env.VUE_APP_ALUMNO_URL;
            } else {
              location.href = process.env.VUE_APP_HOME_URL;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async login() {
      this.isLoading = true;
      try {
        await axios.get("/sanctum/csrf-cookie");
        await axios.post("/login", this.form);

        this.verifyUser();
      } catch (error) {
        this.isLoading = false;

        if (!error.response) {
          this.$buefy.toast.open({
            message: `No es posible validar tus cookies, eliminalas e intenta de nuevo.`,
            position: "is-bottom",
            type: "is-danger"
          });
          return;
        }
        if (error.response.status == 500) {
          this.$buefy.toast.open({
            message: `Ocurrio un problema al conectarse al servidor, intente de nuevo.`,
            position: "is-bottom",
            type: "is-danger"
          });
        } else {
          if (error.response.data) {
            this.$buefy.toast.open({
              message: "Tus credenciales son incorrectas",
              position: "is-bottom",
              type: "is-danger"
            });
          }
        }
        // alert(error.response); // catches both errors
      }
    }
  },
  mounted() {
    this.verifyUser();
  }
};
</script>

<style media="screen">
.login-background-blue {
  background: transparent linear-gradient(0deg, #69c5ff 0%, #0096f5 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  height: 105vh;
}
.aeduc-color {
  color: #0096f5;
  font: normal normal normal 45px/55px Montserrat;
}
.center-image {
  padding-top: 70px;
  display: block;
  margin-left: 5vh;
  margin-right: 5vh;
  width: 70%;
}
.input:focus {
  outline: none !important;
  border: 1px solid #0096f5 !important;
}
</style>
