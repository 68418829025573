import Vue from "vue";
import Vuex from "vuex";
import Buefy from "buefy";
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
import "buefy/dist/buefy.css";

Vue.use(Buefy);
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        auth: false
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
            state.auth = Boolean(user);
        }
    },
    actions: {
        // async login({
        //     dispatch
        // }, credentials) {
        //     // await axios.get("/sanctum/csrf-cookie");
        //     // await axios.post("/login", credentials);
        //     let users = await axios.get('/api/user');
        //     console.log(users);
        //     return dispatch("getUser");
        // },
        // async getUser() {
        //     try {
        //         //
        //     } catch (Exception) {
        //         console.log(Exception);
        //     }
        // }
    },
    modules: {}
});
